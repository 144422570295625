export interface Config {
  serverUrl: string
  defaultAlbumSort: string,
  defaultArtistSort: string,
  defaultGenreSort: string,
  defaultFavouritesSection: string,
  defaultPodcastSort: string,
  defaultPlaylistSort: string,
}

const env = (window as any).env

export const config: Config = {
  serverUrl: env?.SERVER_URL || 'https://aa.widewire.net',
  defaultAlbumSort: env?.DEFAULT_ALBUM_SORT || 'a-z',
  defaultArtistSort: env?.DEFAULT_ARTIST_SORT || 'a-z',
  defaultGenreSort: env?.DEFAULT_GENRE_SORT || 'a-z',
  defaultFavouritesSection: env?.DEFAULT_FAVOURITES_SECTION || 'tracks',
  defaultPodcastSort: env?.DEFAULT_PODCAST_SORT || 'recently-updated',
  defaultPlaylistSort: env?.DEFAULT_PLAYLIST_SORT || 'recently-added',
}
